@use 'sass:color' as sass-color;
@use '@angular/material' as mat;

@mixin color($theme) {
  --table-material-background-color: transparent;
  --table-material-background-color-alt-1: #{sass-color.scale(
      mat.get-theme-color($theme, outline-variant),
      $alpha: -80%
    )};
  --table-material-background-color-alt-2: #{sass-color.scale(
      mat.get-theme-color($theme, outline-variant),
      $alpha: -50%
    )};
  --table-material-background-color-hover: #{sass-color.scale(
      mat.get-theme-color($theme, primary-container),
      $alpha: -60%
    )};
  --table-material-background-color-active: var(--table-material-background-color-hover);
  --table-material-border-color: #{sass-color.scale(mat.get-theme-color($theme, outline-variant), $alpha: -25%)};
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
