@use '@angular/material' as mat;
@use '../../app/tire/tire/tire-theme';
@use '../../app/tire/replacement/replacement-theme';
@use '../../app/tire/movement/movement-theme';
@use '../../app/tire/audit/audit-theme';

@mixin color($theme) {
  background-color: mat.get-theme-color($theme, neutral, 30);

  $spinnerColor: mat.get-theme-color($theme, primary, 60);
  #spinner svg {
    color: $spinnerColor;
    animation: 1s ease-in pulse infinite;
    transform: scale(1);
    filter: drop-shadow(0 0 0 rgba($spinnerColor, 0));

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        filter: drop-shadow(0 0 0 rgba($spinnerColor, 0));
      }

      10% {
        transform: scale(1);
        filter: drop-shadow(0 0 0 rgba($spinnerColor, 0.8));
      }

      65% {
        transform: scale(1);
        filter: drop-shadow(0 0 36px rgba($spinnerColor, 0));
      }

      100% {
        transform: scale(0.95);
        filter: drop-shadow(0 0 0 rgba($spinnerColor, 0));
      }
    }
  }

  #navbar {
    background-color: mat.get-theme-color($theme, primary-container);
    color: mat.get-theme-color($theme, primary);

    .mat-mdc-button,
    .mat-mdc-icon-button {
      color: mat.get-theme-color($theme, primary);
    }
  }

  #sidenav {
    #profile {
      background-color: mat.get-theme-color($theme, secondary-container);
      color: mat.get-theme-color($theme, on-secondary-container);
    }
  }
}

@mixin typography($theme) {
  #app-name {
    font: mat.get-theme-typography($theme, headline-small, font);
    text-transform: capitalize;
  }

  #footer {
    background-color: mat.get-theme-color($theme, secondary-container);
    color: mat.get-theme-color($theme, on-secondary-container);

    #browser-note {
      font: mat.get-theme-typography($theme, body-small, font);
    }
    #copyright {
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}

@mixin all-component-themes($theme) {
  @include theme($theme);
  @include tire-theme.theme($theme);
  @include replacement-theme.theme($theme);
  @include movement-theme.theme($theme);
  @include audit-theme.theme($theme);
}

@mixin css-variables() {
  html body {
    //
  }
}
