@use '@angular/material' as mat;

@mixin color($theme) {
  ign-base-dialog {
    color: mat.get-theme-color($theme, on-surface);

    .mat-toolbar {
      background-color: mat.get-theme-color($theme, primary-container);
      color: mat.get-theme-color($theme, primary);
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
