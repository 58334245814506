@use '@angular/material' as mat;

@mixin color($theme) {
}

@mixin typography($theme) {
  // Conventional changelog creates h1-h3 heading elements, but material 3's default
  // font style for them is too large, so we override them to make it smaller.
  markdown {
    h1 {
      font: mat.get-theme-typography($theme, headline-large, font) !important;
    }
    h2 {
      font: mat.get-theme-typography($theme, headline-medium, font) !important;
    }
    h3 {
      font: mat.get-theme-typography($theme, headline-small, font) !important;
    }
  }
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
