@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Use material 3 theme builder to generate tonal palette

  // +------+-------------------------------+---------+---------+---------+---------+---------+---------+
  // |      |                               | MAIN    | P-10    | P-30    | P-40    | P-80    | P-90    |
  // +------+-------------------------------+---------+---------+---------+---------+---------+---------+
  // | ---- | --MVM STATUS--                |         |         |         |         |         |         |
  // | PUB  | Published                     | #39A2DB | #0E2000 | #2C5000 | #3C6A00 | #82DD00 | #C8EF9E | -> same with RPL-CMP
  // | IPG  | In Progress                   | #FFFF05 | #1D1D00 | #323200 | #626200 | #CDCD00 | #E8E785 | -> same with TRE-PAL, RPL-IPG
  // | PAR  | Paired                        | #34EBE1 | #00201E | #00504C | #006A65 | #0FDDD4 | #90F3EC |
  // | CAN  | Canceled                      | #2C486D | #1A1C1F | #45474B | #5D5E63 | #C6C6CB | #E2E2E7 | -> same with TRE-OMT, RPL-CAN; neutral-variant of tms theme color
  // | ---- | --MVM TYPE--                  |         |         |         |         |         |         |
  // | IMIS | In Misc                       | #9C00FF | #2D004F | #6A00B0 | #8C00E5 | #DFB7FF | #F1DAFF | -> same with MVM-PMIS
  // | IUSE | In USE                        | #F44336 | #410001 | #930005 | #BB1614 | #FFB4A9 | #930005 | -> same with TRE-USE
  // | ORTN | Out Retreading                | #F5B800 | #261A00 | #5B4300 | #795900 | #FABD0D | #FFDF9F | -> same with TRE-RTN
  // | ODSC | Out Discarded                 | #481E14 | #350F07 | #6A392E | #865043 | #FCB6A5 | #FFDAD2 | -> same with TRE-DSC
  // | PMIS | Purchase Misc                 | #9C00FF | #2D004F | #6A00B0 | #8C00E5 | #DFB7FF | #F1DAFF | -> same with MVM-IMIS
  // | PORG | Purchase Original             | #00B89F | #00201A | #005145 | #006B5C | #4BDCC2 | #94F4DE | -> same with TRE-ORG
  // | PRTD | Purchase Retread              | #FC9646 | #301400 | #713700 | #944A00 | #FFB784 | #FFDCC6 | -> same with TRE-RTD
  // | PRTX | Purchase Retread Ex Jasa      | #515DCD | #000766 | #2C38A9 | #4652C2 | #BDC2FF | #DFE0FF | -> same with TRE-RTC, TRE-RTX
  // | PRXL | Purchase Retread Ex Jasa Lite | #515DCD | #000766 | #2C38A9 | #4652C2 | #BDC2FF | #DFE0FF | -> same with TRE-RTC, TRE-RTX
  // +------+-------------------------------+---------+---------+---------+---------+---------+---------+

  $statePalettes: (
    PUB: (
      P-10: #0e2000,
      P-30: #2c5000,
      P-40: #3c6a00,
      P-80: #82dd00,
      P-90: #c8ef9e,
    ),
    IPG: (
      P-10: #1d1d00,
      P-30: #323200,
      P-40: #626200,
      P-80: #cdcd00,
      P-90: #e8e785,
    ),
    PAR: (
      P-10: #00201e,
      P-30: #00504c,
      P-40: #006a65,
      P-80: #0fddd4,
      P-90: #90f3ec,
    ),
    CAN: (
      P-10: #1a1c1f,
      P-30: #45474b,
      P-40: #5d5e63,
      P-80: #c6c6cb,
      P-90: #e2e2e7,
    ),
    IMIS: (
      P-10: #2d004f,
      P-30: #6a00b0,
      P-40: #8c00e5,
      P-80: #dfb7ff,
      P-90: #f1daff,
    ),
    IUSE: (
      P-10: #410001,
      P-30: #930005,
      P-40: #bb1614,
      P-80: #ffb4a9,
      P-90: #930005,
    ),
    ORTN: (
      P-10: #261a00,
      P-30: #5b4300,
      P-40: #795900,
      P-80: #fabd0d,
      P-90: #ffdf9f,
    ),
    ODSC: (
      P-10: #350f07,
      P-30: #6a392e,
      P-40: #865043,
      P-80: #fcb6a5,
      P-90: #ffdad2,
    ),
    PMIS: (
      P-10: #2d004f,
      P-30: #6a00b0,
      P-40: #8c00e5,
      P-80: #dfb7ff,
      P-90: #f1daff,
    ),
    PORG: (
      P-10: #00201a,
      P-30: #005145,
      P-40: #006b5c,
      P-80: #4bdcc2,
      P-90: #94f4de,
    ),
    PRTD: (
      P-10: #301400,
      P-30: #713700,
      P-40: #944a00,
      P-80: #ffb784,
      P-90: #ffdcc6,
    ),
    PRTX: (
      P-10: #000766,
      P-30: #2c38a9,
      P-40: #4652c2,
      P-80: #bdc2ff,
      P-90: #dfe0ff,
    ),
    PRXL: (
      P-10: #000766,
      P-30: #2c38a9,
      P-40: #4652c2,
      P-80: #bdc2ff,
      P-90: #dfe0ff,
    ),
  );

  @each $state, $palette in $statePalettes {
    .MVM-#{$state} {
      &.mat-mdc-chip {
        .mdc-evolution-chip__action:before {
          border-color: transparent !important;
        }
        @if mat.get-theme-type($theme) == dark {
          background-color: map.get($palette, P-30);
          color: map.get($palette, P-80);
        } @else {
          background-color: map.get($palette, P-80);
          color: map.get($palette, P-10);
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
