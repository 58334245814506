@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Use material 3 theme builder to generate tonal palette

  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // |     |                   | MAIN    | P-10    | P-30    | P-40    | P-80    | P-90    |
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // | --- | --RPL STATUS--    |         |         |         |         |         |         |
  // | IPG | In Progress       | #FFFF05 | #1D1D00 | #323200 | #626200 | #CDCD00 | #E8E785 | -> same with TRE-PAL, MVM-IPG
  // | CMP | Completed         | #39A2DB | #0E2000 | #2C5000 | #3C6A00 | #82DD00 | #C8EF9E | -> same with MVM-PUB
  // | CAN | Canceled          | #2C486D | #1A1C1F | #45474B | #5D5E63 | #C6C6CB | #E2E2E7 | -> same with TRE-OMT, MVM-CAN; neutral-variant of tms theme color
  // | --- | --RPL REASON--    |         |         |         |         |         |         |
  // | RSN | Reason            | #39A2DB | #001E2E | #004C6D | #00658F | #86CEFF | #C8E6FF |
  // | --- | --RPL ACTIVE--    |         |         |         |         |         |         |
  // | ACT | Active On Equip   | #FF5BAE | #3D0023 | #8C0056 | #B21671 | #FFB0CF | #FFD8E5 | -> same with TRE-ACT
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+

  $statePalettes: (
    IPG: (
      P-10: #1d1d00,
      P-30: #323200,
      P-40: #626200,
      P-80: #cdcd00,
      P-90: #e8e785,
    ),
    CMP: (
      P-10: #0e2000,
      P-30: #2c5000,
      P-40: #3c6a00,
      P-80: #82dd00,
      P-90: #c8ef9e,
    ),
    CAN: (
      P-10: #1a1c1f,
      P-30: #45474b,
      P-40: #5d5e63,
      P-80: #c6c6cb,
      P-90: #e2e2e7,
    ),
    RSN: (
      P-10: #001e2e,
      P-30: #004c6d,
      P-40: #00658f,
      P-80: #86ceff,
      P-90: #c8e6ff,
    ),
    ACT: (
      P-10: #3d0023,
      P-30: #8c0056,
      P-40: #b21671,
      P-80: #ffb0cf,
      P-90: #ffd8e5,
    ),
  );

  @each $state, $palette in $statePalettes {
    .RPL-#{$state} {
      &.mat-mdc-chip {
        .mdc-evolution-chip__action:before {
          border-color: transparent !important;
        }
        @if mat.get-theme-type($theme) == dark {
          background-color: map.get($palette, P-30);
          color: map.get($palette, P-80);
        } @else {
          background-color: map.get($palette, P-80);
          color: map.get($palette, P-10);
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
