@use 'sass:color' as sass-color;
@use '@angular/material' as mat;

@mixin color($theme) {
  // Set internal scrollbars to dark on dark mode. Note that main page scrollbar
  // is unaffected because that needs the color-scheme to set under :root
  // selector meanwhile here it is under body.dark-theme selector.
  @if mat.get-theme-type($theme) == dark {
    color-scheme: dark;
  }

  // Set anchor element using tertiary color.
  $a-color: mat.get-theme-color($theme, tertiary);
  // Hue 60 happens to be suitable for both dark and light modes.
  $a-hover-color: mat.get-theme-color($theme, tertiary, 60);
  a {
    text-decoration: none;
    color: $a-color;
    &:hover {
      color: $a-hover-color;
    }
  }

  // Set dimmed text and anchor element color used for e.g. showing canceled or deleted data.
  .dimmed {
    @if (mat.get-theme-type($theme) == dark) {
      color: sass-color.scale(mat.get-theme-color($theme, on-surface), $lightness: -50%);
    } @else {
      color: sass-color.scale(mat.get-theme-color($theme, on-surface), $lightness: 60%);
    }
  }
  a.dimmed,
  .dimmed a {
    @if (mat.get-theme-type($theme) == dark) {
      color: sass-color.scale($a-color, $lightness: -50%);
    } @else {
      color: sass-color.scale($a-color, $lightness: 60%);
    }
    &:hover {
      @if (mat.get-theme-type($theme) == dark) {
        color: sass-color.scale($a-hover-color, $lightness: -10%);
      } @else {
        color: sass-color.scale($a-hover-color, $lightness: 60%);
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
