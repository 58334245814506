@use '@angular/material' as mat;

@mixin color($theme) {
  .mat-toolbar {
    background-color: mat.get-theme-color($theme, secondary-container);
    color: mat.get-theme-color($theme, on-secondary-container);
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
