@use 'sass:color' as sass-color;
@use '@angular/material' as mat;

@mixin color($theme) {
  // The group node for where favourite fields (.where-favourite) is not an actual ign-node component.
  // It's a div housing a card with style similar to ign-node component's group node for consistency.

  ign-node,
  .where-favourite {
    // Button label color for group node header and field node content.
    .field-node-content:not(:disabled),
    .group-node-header:not(:disabled) {
      color: mat.get-theme-color($theme, on-secondary-container);
    }

    // Add an outline under group node header.
    .group-node-header {
      border-bottom-color: mat.get-theme-color($theme, outline-variant);
    }

    // Background color of group node header and field node content during hover and activated state.
    &:hover,
    &.node-active {
      > .group-node,
      > .fav-group-node {
        > .mat-mdc-card-header {
          .group-node-header {
            background-color: mat.get-theme-color($theme, secondary-container);
          }
        }
      }
      > .field-node {
        .field-node-content {
          background-color: mat.get-theme-color($theme, secondary-container);
        }
      }
    }
  }

  ign-keyword-input {
    .mat-mdc-form-field-hint {
      color: mat.get-theme-color($theme, on-surface-variant);
    }
  }

  .where-builder {
    .mat-icon.non-empty-star {
      color: mat.get-theme-color($theme, secondary);
    }
  }
}

@mixin typography($theme) {
  .where-builder {
    .mat-button-toggle {
      .mat-button-toggle-label-content {
        font: mat.get-theme-typography($theme, label-small, font);
        text-transform: uppercase;
        line-height: 30px;
      }
    }
  }
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
