@use '@angular/material' as mat;

@mixin mat-button($theme) {
  // Make mat-raised-button more popped-up on dark theme because by default its background-color
  // is the same with its container (e.g. .mat-drawer-container or .mat-mdc-dialog-surface),
  // only outlined by thin shadow line with the same dark color which makes it unclear.
  // The default background-color is ok on light theme because it's a light color
  // with dark colored shadow line which makes the outline bold and clear enough.
  .mat-mdc-raised-button:not(.mat-mdc-button-disabled) {
    @if (mat.get-theme-type($theme) == dark) {
      background-color: mat.get-theme-color($theme, surface-container-high);
    }
  }
}

@mixin mat-card($theme) {
  // Make mat-card more popped-up on dark theme because by default its background-color
  // is the same with its container (e.g. .mat-drawer-container or .mat-mdc-dialog-surface),
  // only outlined by thin shadow line with the same dark color which makes it unclear.
  // The default background-color is ok on light theme because it's a light color
  // with dark colored shadow line which makes the outline bold and clear enough.
  .mat-mdc-card {
    @if (mat.get-theme-type($theme) == dark) {
      background-color: mat.get-theme-color($theme, surface-container);
    }
  }
}
