@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
@use '@davidhiu/ignite-ng/src' as ign;
@use './app-theme' as app;
@use './palettes';

@use './ag-grid-theme' as agg;
@use './fonts';

$m3-light-theme: matx.define-theme(
  (
    color: (
      primary: palettes.$m3-steel-blue-palette,
      tertiary: palettes.$m3-orange-palette,
    ),
  )
);

$m3-dark-theme: matx.define-theme(
  (
    color: (
      theme-type: dark,
      primary: palettes.$m3-steel-blue-palette,
      tertiary: palettes.$m3-orange-palette,
    ),
  )
);

html {
  @include mat.core();

  @include mat.all-component-typographies();
  @include mat.typography-hierarchy($m3-light-theme);
  @include mat.all-component-themes($m3-light-theme);
  @include ign.all-component-themes($m3-light-theme);
  @include ign.mat-color-variants($m3-light-theme);
  @include app.all-component-themes($m3-light-theme);

  .dark-theme {
    @include mat.all-component-colors($m3-dark-theme);
    @include ign.all-component-colors($m3-dark-theme);
    @include ign.mat-color-variants($m3-dark-theme);
    @include app.all-component-themes($m3-dark-theme);
  }
}

@include app.css-variables();
@include agg.css-variables();
