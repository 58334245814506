@use 'sass:color' as sass-color;
@use '@angular/material' as mat;

@mixin color($theme) {
  // Highlight MatFormField only for filled appearance.
  // Highlight DataListComponent by adding warn color border box.
  // To use, apply the toggling wrapper class .highlight-field on a div wrapping the form-fields.
  .highlight-field {
    // Highlight MatFormField.
    .mat-mdc-form-field {
      // Valid state.
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        // Set background color.
        background-color: sass-color.scale(mat.get-theme-color($theme, primary-container), $alpha: -50%);
      }

      // Error state.
      &.highlight-error {
        // Set background and line ripple color.
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
          background-color: sass-color.scale(mat.get-theme-color($theme, error-container), $alpha: -50%);
          .mdc-line-ripple--active::after {
            border-bottom-color: mat.get-theme-color($theme, error);
          }
        }

        // Set hint label color by targeting only dynamicaly inserted hint label element by
        // mat-form-field's hintLabel property which is performed by ignErrorHighlighter.
        // This allows non-error hint to not being colored red when there's error.
        .mat-mdc-form-field-hint.ng-star-inserted {
          color: mat.get-theme-color($theme, error);
        }
      }
    }

    // Highlight DataListComponent.
    ign-data-list {
      // Error state.
      &.highlight-error {
        ag-grid-angular {
          border: 2px solid mat.get-theme-color($theme, error);
          box-sizing: border-box;
        }
      }
    }
  }

  // Prevent default behavior of dimming text color of disabled form-fields.
  // To use, apply the toggling wrapper class .bright-disabled-field on a div wrapping the form-fields.
  .bright-disabled-field {
    .mdc-text-field--disabled .mdc-text-field__input,
    .mdc-text-field--disabled .mdc-floating-label,
    .mat-mdc-select-disabled,
    .mdc-checkbox--disabled .mdc-label {
      color: mat.get-theme-color($theme, on-surface);
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
