@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .mat-mdc-nav-list {
    a.mat-mdc-list-item-title {
      // Default menu item's title and icon color.
      & .mdc-list-item__primary-text,
      & mat-icon {
        color: mat.get-theme-color($theme, on-surface-variant);
      }

      // Active menu item's title and icon color.
      &.active .mdc-list-item__primary-text,
      &.active mat-icon {
        color: mat.get-theme-color($theme, primary);
      }

      // Hovered menu item's title and icon color.
      &:hover:not(.active) .mdc-list-item__primary-text,
      &:hover:not(.active) mat-icon {
        color: mat.get-theme-color($theme, tertiary);
      }
    }
  }

  // Menu group's title and icon color.
  .mat-expansion-panel {
    .mat-expansion-panel-header .mat-content {
      color: mat.get-theme-color($theme, on-surface-variant);
    }
  }

  // Menu item's active background color.
  .mat-mdc-nav-list a.mat-mdc-list-item-title.active {
    @if (mat.get-theme-type($theme) == dark) {
      background-color: rgba(mat.get-theme-color($theme, primary-container), 0.83);
    } @else {
      background-color: rgba(mat.get-theme-color($theme, primary-container), 0.83);
    }
  }

  // Menu group's hover background color.
  // Note that menu item already has hover background color from mat-nav-list.
  .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    @if (mat.get-theme-type($theme) == dark) {
      background-color: rgba(mat.get-theme-color($theme, neutral, 20), 0.83);
    } @else {
      background-color: rgba(mat.get-theme-color($theme, neutral, 90), 0.83);
    }
  }

  // Disable the hover on touch devices, refer to mat-expansion's source code.
  @media (hover: none) {
    .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background-color: mat.get-theme-color($theme, surface);
    }
  }
}

@mixin typography($theme) {
  // Override panel header's label font to be the same with mat-nav-list's.
  .mat-expansion-panel-header {
    font: mat.get-theme-typography($theme, body-large, font);
  }
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
