@use '@angular/material' as mat;

/**
* Emit color variants for certain angular material components.
* Note that primary variant is already emitted by angular.
*/
@mixin mat-color-variants($theme) {
  .mdc-button {
    &.tertiary-variant {
      @include mat.button-color($theme, $color-variant: tertiary);
    }
    &.error-variant {
      @include mat.button-color($theme, $color-variant: error);
    }
  }

  @include fab-color($theme);

  @include icon-button-color($theme);
}

/**
* Implement mdc-icon-button's all three color variants as they are not available on M3 specs.
*/
@mixin icon-button-color($theme) {
  $primary: mat.get-theme-color($theme, primary);
  $tertiary: mat.get-theme-color($theme, tertiary);
  $error: mat.get-theme-color($theme, error);

  .mdc-icon-button:not(:disabled) {
    &.primary-variant {
      --mdc-icon-button-icon-color: #{$primary};
      --mat-icon-button-ripple-color: #{rgba($primary, 0.12)};
      --mat-icon-button-state-layer-color: #{$primary};
    }
    &.tertiary-variant {
      --mdc-icon-button-icon-color: #{$tertiary};
      --mat-icon-button-ripple-color: #{rgba($tertiary, 0.12)};
      --mat-icon-button-state-layer-color: #{$tertiary};
    }
    &.error-variant {
      --mdc-icon-button-icon-color: #{$error};
      --mat-icon-button-ripple-color: #{rgba($error, 0.12)};
      --mat-icon-button-state-layer-color: #{$error};
    }
  }
}

/**
* Implement mdc-fab's tertiary and error color variants as they are not available on M3 specs.
* Actually tertiary is available on the spec, but we use our own implementation for consistency.
*/
@mixin fab-color($theme) {
  $tertiary-container: mat.get-theme-color($theme, tertiary-container);
  $on-tertiary-container: mat.get-theme-color($theme, on-tertiary-container);
  $error-container: mat.get-theme-color($theme, error-container);
  $on-error-container: mat.get-theme-color($theme, on-error-container);

  .mdc-fab:not(:disabled) {
    &.tertiary-variant {
      --mdc-fab-container-color: #{$tertiary-container};
      --mat-fab-ripple-color: #{rgba($on-tertiary-container, 0.12)};
      --mat-fab-foreground-color: #{on-tertiary-container};
      --mat-fab-state-layer-color: #{on-tertiary-container};
      --mdc-fab-small-container-color: #{$tertiary-container};
      --mat-fab-small-ripple-color: #{rgba($on-tertiary-container, 0.12)};
      --mat-fab-small-foreground-color: #{on-tertiary-container};
      --mat-fab-small-state-layer-color: #{on-tertiary-container};
    }
    &.error-variant {
      --mdc-fab-container-color: #{$error-container};
      --mat-fab-ripple-color: #{rgba($on-error-container, 0.12)};
      --mat-fab-foreground-color: #{on-error-container};
      --mat-fab-state-layer-color: #{on-error-container};
      --mdc-fab-small-container-color: #{$error-container};
      --mat-fab-small-ripple-color: #{rgba($on-error-container, 0.12)};
      --mat-fab-small-foreground-color: #{on-error-container};
      --mat-fab-small-state-layer-color: #{on-error-container};
    }
  }
}
