@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Use material 3 theme builder to generate tonal palette

  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // |     |                   | MAIN    | P-10    | P-30    | P-40    | P-80    | P-90    |
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // | --- | --TRE STATUS--    |         |         |         |         |         |         |
  // | NEW | New               | #9C27B0 | #35003F | #7B008F | #9A25AE | #F9ABFF | #FFD6FE |
  // | PAL | Pre Installed     | #FFFF05 | #1D1D00 | #323200 | #626200 | #CDCD00 | #E8E785 | -> same with RPL-IPG, MVM-IPG
  // | TAL | Installed         | #43A047 | #002204 | #005313 | #006E1C | #7DDC7A | #B6F2AF |
  // | USE | Used              | #F44336 | #410001 | #930005 | #BB1614 | #FFB4A9 | #930005 | -> same with MVM-IUSE
  // | RTN | Retreading        | #F5B800 | #261A00 | #5B4300 | #795900 | #FABD0D | #FFDF9F | -> same with MVM-ORTN
  // | RTC | Retread Completed | #515DCD | #000766 | #2C38A9 | #4652C2 | #BDC2FF | #DFE0FF | -> same with TRE-RTX, MVM-PRTX, MVM-PRXL
  // | DSC | Discarded         | #481E14 | #350F07 | #6A392E | #865043 | #FCB6A5 | #FFDAD2 | -> same with MVM-ODSC
  // | OMT | Omitted           | #2C486D | #1A1C1F | #45474B | #5D5E63 | #C6C6CB | #E2E2E7 | -> same with RPL-CAN, MVM-CAN; neutral-variant of tms theme color
  // | --- | --TRE CATEGORY--  |         |         |         |         |         |         |
  // | ORG | Original          | #00B89F | #00201A | #005145 | #006B5C | #4BDCC2 | #94F4DE | -> same with MVM-PORG
  // | RTD | Retread           | #FC9646 | #301400 | #713700 | #944A00 | #FFB784 | #FFDCC6 | -> same with MVM-PRTD
  // | RTX | Retread Ex Jasa   | #515DCD | #000766 | #2C38A9 | #4652C2 | #BDC2FF | #DFE0FF | -> same with TRE-RTC, MVM-PRTX, MVM-PRXL
  // | --- | --TRE ACTIVE--    |         |         |         |         |         |         |
  // | ACT | Active On Equip   | #FF5BAE | #3D0023 | #8C0056 | #B21671 | #FFB0CF | #FFD8E5 | -> samw with RPL-ACT
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+

  $statePalettes: (
    NEW: (
      P-10: #35003f,
      P-30: #7b008f,
      P-40: #9a25ae,
      P-80: #f9abff,
      P-90: #ffd6fe,
    ),
    PAL: (
      P-10: #1d1d00,
      P-30: #323200,
      P-40: #626200,
      P-80: #cdcd00,
      P-90: #e8e785,
    ),
    TAL: (
      P-10: #002204,
      P-30: #005313,
      P-40: #006e1c,
      P-80: #7ddc7a,
      P-90: #b6f2af,
    ),
    USE: (
      P-10: #410001,
      P-30: #930005,
      P-40: #bb1614,
      P-80: #ffb4a9,
      P-90: #930005,
    ),
    RTN: (
      P-10: #261a00,
      P-30: #5b4300,
      P-40: #795900,
      P-80: #fabd0d,
      P-90: #ffdf9f,
    ),
    RTC: (
      P-10: #000766,
      P-30: #2c38a9,
      P-40: #4652c2,
      P-80: #bdc2ff,
      P-90: #dfe0ff,
    ),
    DSC: (
      P-10: #350f07,
      P-30: #6a392e,
      P-40: #865043,
      P-80: #fcb6a5,
      P-90: #ffdad2,
    ),
    OMT: (
      P-10: #1a1c1f,
      P-30: #45474b,
      P-40: #5d5e63,
      P-80: #c6c6cb,
      P-90: #e2e2e7,
    ),
    ORG: (
      P-10: #00201a,
      P-30: #005145,
      P-40: #006b5c,
      P-80: #4bdcc2,
      P-90: #94f4de,
    ),
    RTD: (
      P-10: #301400,
      P-30: #713700,
      P-40: #944a00,
      P-80: #ffb784,
      P-90: #ffdcc6,
    ),
    RTX: (
      P-10: #000766,
      P-30: #2c38a9,
      P-40: #4652c2,
      P-80: #bdc2ff,
      P-90: #dfe0ff,
    ),
    ACT: (
      P-10: #3d0023,
      P-30: #8c0056,
      P-40: #b21671,
      P-80: #ffb0cf,
      P-90: #ffd8e5,
    ),
  );

  @each $state, $palette in $statePalettes {
    .TRE-#{$state} {
      &.mat-mdc-chip {
        .mdc-evolution-chip__action:before {
          border-color: transparent !important;
        }
        @if mat.get-theme-type($theme) == dark {
          background-color: map.get($palette, P-30);
          color: map.get($palette, P-80);
        } @else {
          background-color: map.get($palette, P-80);
          color: map.get($palette, P-10);
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
