@mixin style() {
  body {
    margin: 0;
    scroll-behavior: smooth;
  }

  .table-property {
    tr td:first-child {
      width: 150px;
      font-weight: bold;
    }
  }

  app-root {
    #spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;

      svg {
        width: 96px;
        height: 96px;
      }

      animation: 2.8s cubic-bezier(1, 0, 1, -0.03) fadeOut forwards;
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }

    & > *:not(#spinner) {
      animation: 0.5s ease-out fadeIn forwards;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}

@mixin css-variables() {
  html body {
    --mdc-dialog-container-shape: 21px;
  }
}
