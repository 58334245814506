@use 'sass:math';

$table-header-font-weight: bold;
$table-header-font-color: inherit;

$table-cell-padding: 1.2rem;
$table-condensed-cell-padding: math.div($table-cell-padding, 2);

@mixin table-material() {
  // Baseline
  .table {
    width: 100%;
    max-width: 100%;
    background-color: var(--table-material-background-color);
    border-spacing: 0;
    > thead,
    > tbody,
    > tfoot,
    & {
      > tr {
        > th,
        > td {
          text-align: left;
          padding: $table-cell-padding;
          vertical-align: top;
          border-top: 0;
        }
      }
    }
    > thead,
    & {
      > tr > th {
        font-weight: $table-header-font-weight;
        color: $table-header-font-color;
        vertical-align: bottom;
        border-bottom: 1px var(--table-material-border-color);
      }
    }
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
      > tr:first-child {
        > th,
        > td {
          border-top: 0;
        }
      }
    }
    > tbody + tbody {
      border-top: 1px solid var(--table-material-border-color);
    }

    // Nesting
    .table {
      background-color: var(--table-material-background-color);
    }

    // Remove border
    .no-border {
      border: 0;
    }
  }

  // Condensed cells
  .table-condensed {
    > thead,
    > tbody,
    > tfoot,
    & {
      > tr {
        > th,
        > td {
          padding: $table-condensed-cell-padding;
        }
      }
    }
  }

  // Bordered rows
  .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot,
    & {
      > tr {
        > th,
        > td {
          border: 0;
          border-bottom: 1px solid var(--table-material-border-color);
        }
      }
    }
    > thead,
    & {
      > tr {
        > th,
        > td {
          border-bottom-width: 2px;
        }
      }
    }
  }

  // Row striped
  .table-striped {
    thead > tr > th,
    tfoot > tr > th {
      background-color: var(--table-material-background-color-alt-2);
    }

    tbody > tr:nth-child(even) > td,
    > tr:nth-child(odd) > th,
    > tr:nth-child(odd) > td {
      background-color: var(--table-material-background-color-alt-1);
    }
  }

  // Row hover
  .table-hover {
    > thead,
    > tbody,
    > tfoot,
    & {
      > tr:hover {
        > td,
        > th {
          background-color: var(--table-material-background-color-hover);
        }
      }
    }
  }

  // Responsive auto table scrolling on x-axis.
  .table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
  }

  // Responsive tables (vertical)
  //
  // Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
  // by vertical table-cell display. Only applies <768px. Everything above that will display normally.
  // For correct display you must add 'data-title' to each 'td'
  .table-responsive-vertical {
    @media screen and (max-width: 768px) {
      // Tighten up spacing
      & > .table {
        background-color: var(--table-material-background-color);
        > thead,
        > tfoot {
          display: none;
        }

        > tbody,
        & {
          display: block;

          > tr {
            display: block;
            border: 1px solid var(--table-material-border-color);
            border-radius: 2px;
            margin-bottom: $table-cell-padding;

            > td {
              background-color: var(--table-material-background-color);
              display: block;
              text-align: right;
            }
            > td[data-title]:before {
              content: attr(data-title);
              float: left;
              font-size: inherit;
              font-weight: $table-header-font-weight;
              color: $table-header-font-color;
            }
          }
        }
      }

      // Special overrides for the bordered tables
      & > .table-bordered {
        border: 0;

        // Nuke the appropriate borders so that the parent can handle them
        > tbody,
        & {
          > tr {
            > td {
              border: 0;
              border-bottom: 1px solid var(--table-material-border-color);
            }
            > td:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      // Special overrides for the striped tables
      & > .table-striped {
        > tbody,
        & {
          > tr > td,
          > tr:nth-child(odd) {
            background-color: var(--table-material-background-color);
          }
          > tr > td:nth-child(odd) {
            background-color: var(--table-material-background-color-alt-1);
          }
        }
      }

      // Special overrides for hover tables
      & > .table-hover {
        > tbody,
        & {
          > tr:hover > td,
          > tr:hover {
            background-color: var(--table-material-background-color);
          }
          > tr > td:hover {
            background-color: var(--table-material-background-color-hover);
          }
        }
      }
    }
  }
}
