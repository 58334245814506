@mixin mat-typography-hierarchy() {
  .mat-typography {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // Give default margins to be used along side mat-form-field.
      &.form-field {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}

@mixin mat-form-field() {
  .mat-mdc-form-field,
  ign-keyword-input,
  ign-datetimepicker {
    // Give default margins.
    margin: 3px 8px;
  }

  .mat-mdc-form-field {
    // Allow textarea inside form field to be 100% height by applying .full-height-textarea.
    &.full-height-textarea {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          height: 100%;

          .mat-mdc-form-field-infix {
            height: 100%;

            textarea {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@mixin mat-select() {
  .mat-mdc-select {
    // Enable user to select displayed text, especially when disabled.
    .mat-mdc-select-value-text {
      user-select: all;
    }
  }
}

@mixin mat-sort() {
  // Selector added to add specificity.
  .mat-sort .mat-sort-header-content {
    text-align: left;
  }
}

@mixin mat-button() {
  // Selector added to add specificity.
  .mdc-button.mat-mdc-button-base {
    text-transform: uppercase;
  }
}
