@use 'sass:color' as sass-color;
@use '@angular/material' as mat;

@mixin color($theme) {
  [class*='ag-theme-'] {
    // Header foreground and background.
    --ag-header-foreground-color: #{sass-color.scale(mat.get-theme-color($theme, on-surface), $lightness: 20%)};
    --ag-header-background-color: #{mat.get-theme-color($theme, primary-container)};

    // Body foreground and background.
    --ag-foreground-color: #{mat.get-theme-color($theme, on-surface)};
    --ag-background-color: #{mat.get-theme-color($theme, surface)};
    @if (mat.get-theme-type($theme) == dark) {
      --ag-odd-row-background-color: #{mat.get-theme-color($theme, surface-container-high)};
    } @else {
      --ag-odd-row-background-color: #{mat.get-theme-color($theme, surface-container-low)};
    }

    // Row hover and selected background.
    @if (mat.get-theme-type($theme) == dark) {
      --ag-row-hover-color: #{sass-color.scale(mat.get-theme-color($theme, secondary-container), $alpha: -20%)};
      --ag-selected-row-background-color: #{sass-color.scale(
          mat.get-theme-color($theme, primary-container),
          $alpha: -60%
        )};
    } @else {
      --ag-row-hover-color: #{sass-color.scale(mat.get-theme-color($theme, secondary-container), $alpha: -0%)};
      --ag-selected-row-background-color: #{sass-color.scale(
          mat.get-theme-color($theme, primary-container),
          $alpha: -50%
        )};
    }

    // Cell range selection and cell focus background.
    --ag-range-selection-background-color: #{sass-color.scale(mat.get-theme-color($theme, primary), $alpha: -80%)};
    --ag-range-selection-background-color-2: #{sass-color.scale(mat.get-theme-color($theme, primary), $alpha: -64%)};
    --ag-range-selection-background-color-3: #{sass-color.scale(mat.get-theme-color($theme, primary), $alpha: -51%)};
    --ag-range-selection-background-color-4: #{sass-color.scale(mat.get-theme-color($theme, primary), $alpha: -41%)};
    --ag-balham-active-color: #{sass-color.scale(mat.get-theme-color($theme, primary), $alpha: -20%)};
    .ag-cell-focus {
      background-color: mat.get-theme-color($theme, primary-container);
    }

    // No-data and loading box-overlay's style.
    .ag-overlay-wrapper {
      span {
        color: mat.get-theme-color($theme, on-surface);
        background-color: mat.get-theme-color($theme, surface);
        border-color: mat.get-theme-color($theme, outline);
      }
    }
    .ag-overlay-loading-wrapper {
      background-color: sass-color.scale(mat.get-theme-color($theme, surface-container), $alpha: -60%);
    }

    // Highlight editable cell, similar idea with highlighted form-field.
    // To use, apply the class .highlight on a columnDef's cellClass property.
    @if (mat.get-theme-type($theme) == dark) {
      .ag-row:not(.ag-row-selected):not(.ag-row-hover) {
        .ag-cell.highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: sass-color.scale(mat.get-theme-color($theme, primary, 20), $alpha: -50%);
        }
      }
    } @else {
      .ag-row:not(.ag-row-selected):not(.ag-row-hover) {
        .ag-cell.highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: sass-color.scale(mat.get-theme-color($theme, primary, 90), $alpha: -60%);
        }
      }
    }
  }
}

@mixin typography($theme) {
  .ag-theme-balham,
  .ag-theme-balham-dark {
    &,
    .ag-header {
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }

  // Data-list's validation error hint message.
  .mat-mdc-form-field-error {
    font: mat.get-theme-typography($theme, body-small, font);
  }
}

@mixin density($theme) {
  .ag-theme-balham,
  .ag-theme-balham-dark {
    --ag-row-border-width: 0;
    --ag-cell-horizontal-border: var(--ag-row-border-width) var(--ag-row-border-style) var(--ag-row-border-color);
    --ag-cell-border: 1 var(--ag-row-border-style) var(--ag-row-border-color);

    .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell {
      line-height: 28px;
    }

    // ".ag-cell-wrapper > *" could be below classes:
    // .ag-selection-checkbox, .ag-group-expanded, .ag-group-contracted, .ag-group-checkbox, .ag-group-value, .ag-group-child-count
    .ag-cell-wrapper > *,
    .ag-cell-inline-editing {
      height: 28px;
    }
  }

  .ag-overlay-wrapper {
    span {
      border-width: 1px;
      border-style: solid;
      padding: 10px;
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
