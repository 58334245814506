@use '@angular/material' as mat;

@mixin color($theme) {
  ign-notification-snackbar {
    &::before {
      border-color: mat.get-theme-color($theme, primary);
    }
    &.warn::before {
      @if (mat.get-theme-type($theme) == dark) {
        border-color: mat.get-theme-color($theme, error, 70);
      } @else {
        border-color: mat.get-theme-color($theme, error, 40);
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
