@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Use material 3 theme builder to generate tonal palette

  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // |     |                   | MAIN    | P-10    | P-30    | P-40    | P-80    | P-90    |
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+
  // |     | Match             | #4CAF50 | #002204 | #005313 | #006E1C | #78DC77 | #B6F2AF | -> primary
  // +-----+-------------------+---------+---------+---------+---------+---------+---------+

  $matchPalette: (
    P-10: #002204,
    P-30: #005313,
    P-40: #006e1c,
    P-80: #78dc77,
    P-90: #b6f2af,
  );

  .match {
    &.mat-mdc-chip {
      .mdc-evolution-chip__action:before {
        border-color: transparent !important;
      }
      @if mat.get-theme-type($theme) == dark {
        background-color: map.get($matchPalette, P-30);
        color: map.get($matchPalette, P-80);
      } @else {
        background-color: map.get($matchPalette, P-80);
        color: map.get($matchPalette, P-10);
      }
    }

    &.mat-icon {
      @if mat.get-theme-type($theme) == dark {
        color: map.get($matchPalette, P-40);
      } @else {
        color: map.get($matchPalette, P-80);
      }
    }
  }

  // Use softer than on-surface color for unmatch state.
  .unmatch.mat-icon {
    color: mat.get-theme-color($theme, outline);
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
  @if mat.theme-has($theme, density) {
    @include density($theme);
  }
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
